<template>
  <v-card class="ma-2">
    <v-img
      :src="cardData.imgSrc"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,0.5)"
      height="300px"
    >
      <v-card-title>
        {{cardData.title}}
      </v-card-title>
    </v-img>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-chip-group>
          <v-chip
            v-for="tag in cardData.tags"
            :key="tag"
            class="ma-1"
            :ripple="false"
            :color="tagColorDict[tag]"
            text-color="white"
            label
          >
            {{tag}}
          </v-chip>
        </v-chip-group>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="true">
        <v-divider></v-divider>
        <v-card-text>
          {{ cardData.subText }}
        </v-card-text>
        <div class="pl-2 pb-2">
        <!--
          <v-btn
           color="indigo"
           v-if='cardData.detailPage!=""'
           :to="cardData.detailPage"
           target="_self"
           class="white--text"
           rounded
          >
            Read More
          </v-btn>
-->

          <v-btn
           color="indigo"
           icon
           v-if='cardData.paper!=""'
           :href="cardData.paper"
           target="_blank"
          >
            <v-icon color="indigo">mdi-file-outline</v-icon>
          </v-btn>

          <v-btn
           color="indigo"
           icon
           v-if='cardData.video!=""'
           :href="cardData.video"
           target="_blank"
          >
            <v-icon color="indigo">mdi-youtube</v-icon>
          </v-btn>

          <v-btn
           color="indigo"
           icon
           v-if='cardData.github!=""'
           :href="cardData.github"
           target="_blank"
          >
            <v-icon color="indigo">mdi-github-circle</v-icon>
          </v-btn>

          <v-btn
           color="indigo"
           icon
           v-if='cardData.link!=""'
           :href="cardData.link"
           target="_blank"
          >
            <v-icon color="indigo">mdi-link</v-icon>
          </v-btn>

        </div>

      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
  export default {
    name: 'ProjectCard',

    props: ['cardData'],

    methods: {
    },

    data () {
      return {
        tagColorDict: {
          'AR/VR': 'blue darken-2',
          'Haptics': 'black',
          'Education': 'yellow darken-3',
          'Medication': 'teal',
          'Visualization': 'indigo darken-1',
          'Accessibility': 'green darken-1',
          'Collaboration': 'amber darken-4',
          'Information Retrieval': 'brown darken-1',
          'Crowdsourcing': 'blue-grey darken-1',
          'Interaction Design': 'grey darken-1',
          'Wearable': 'green darken-4',
          'Android Application': 'blue-grey darken-4'
        }
      }
    },
  }
</script>
