<template>
  <div>
    <v-app-bar
      color="amber lighten-1"
      flat
      fixed
      density="prominent"
      style = "align-content: center;"
    >
      <v-img
        contain
        max-height = 2.5em
        max-width = 9em
        :src="logo2Scr"
      />
      <v-img
        contain
        max-height = 2.5em
        max-width = 2.5em
        :src="logoScr"
      />

      <span class="d-none d-sm-flex" style="position: absolute; right: 12px;">
        <v-btn
          v-for="link in links"
          :key="link.id"
          text
          class="mt-1 mb-1"
          color="indigo"
          height = 3em
          rounded
        >
          <router-link
           :to="link.path"
           :target="link.target"
           style="text-decoration: none; color: inherit;"
          >
            {{ link.text }}
          </router-link>
        </v-btn>
      </span>

      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="d-flex d-sm-none"
        style="position: absolute; right: 12px; margin: 0;"
      />

    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      temporary
      location="bottom"
      style="position:fixed; top:0; left:0; overflow-y:scroll;"
    >
      <v-list nav dense>
        <v-list-item-group
         v-model="group"
         active-class="deep-purple--text text--accent-4"
        >
          <v-list-item
           v-for="link in links"
           :key="link.id"
           :to="link.path"
           :target="link.target"
          >
            <v-list-item-title> {{ link.text }} </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

  </div>
</template>

<script>
  export default {
    name: 'Navigation',

    data () {
      return {
        drawer: false,
        group: null,
        links: [
          { id: 0, target: '_self', text: 'Home', path:'/' },
          { id: 1, target: '_self', text: 'Publications', path:'/publications' },
          { id: 2, target: '_self', text: 'Projects', path:'/projects' },
          { id: 3, target: '_blank', text: 'CV', path: '/documents/xizi_lucy_wang_cv.pdf'}
        ],
        logoScr: require('./../images/xizi_lucy_wang_web_logo.png'),
        logo2Scr: require('./../images/xizi_lucy_wang_web_logo_word.png')
      }
    },
    watch: {
      group () {
        this.drawer = false
      },
    },
  }
</script>

<style>

</style>
