<template>
  <v-container>
    <h3>Peer-review Conference Paper</h3>
    <PublicationList listType="Conference"/>

    <h3>Peer-reivew Journal Paper</h3>
    <PublicationList listType="Journal"/>

    <h3>Thesis</h3>
    <PublicationList listType="Thesis"/>

    <h3>Student Design Competition</h3>
    <PublicationList listType="Competition"/>

  </v-container>
</template>

<script>
import PublicationList from './PublicationList';

export default {
  name: 'PublicationsPage',
  components: {
    PublicationList
  },
  methods: {
    login () {
      this.$gtag.event('login', { method: 'Google' })
    }
  },
  data () {
    return {

    }
  },
};
</script>
