<template>
  <div class="d-flex flex-column flex-sm-row">
    <v-card
     max-width="10em"
     max-height="6em"
     flat
     class="align-self-center my-3 mr-4"
    >
      <v-img
       :src="publicationData.imgSrc"
       class="mr-4"
       width=100%
       max-height="6em"
      />
    </v-card>

    <v-list-item-content>
      <div v-html="publicationData.content"></div>
      <div
       class="mt-1"
      >
        <div style="float:left;">
          <v-chip
            v-if="publicationData.comingSoon"
            class="ma-1"
            :ripple="false"
            color="indigo"
            text-color="white"
            label
            x-small
          >
            Coming Soon
          </v-chip>
        </div>

        <div
         class="align-self-center"
         style="display:flex; flex-direction:row; float:left;"
        >
          <v-btn
           color="indigo"
           icon
           v-if='publicationData.paper!=""'
           :href="publicationData.paper"
           target="_blank"
           x-small
          >
            <v-icon color="indigo">mdi-file-outline</v-icon>
          </v-btn>

          <v-btn
           color="indigo"
           icon
           v-if='publicationData.video!=""'
           :href="publicationData.video"
           target="_blank"
           x-small
          >
            <v-icon color="indigo">mdi-youtube</v-icon>
          </v-btn>

          <v-btn
           color="indigo"
           icon
           v-if='publicationData.github!=""'
           :href="publicationData.github"
           target="_blank"
           x-small
          >
            <v-icon color="indigo">mdi-github-circle</v-icon>
          </v-btn>

          <v-btn
           color="indigo"
           icon
           v-if='publicationData.link!=""'
           :href="publicationData.link"
           target="_blank"
           x-small
          >
            <v-icon color="indigo">mdi-link</v-icon>
          </v-btn>
        </div>

        <div style="float:right;">
          <span v-if="publicationData.isBestPaper">
            <v-icon color="amber lighten-1">mdi-trophy-variant</v-icon>
          </span>
          <v-chip
            v-if="publicationData.nameOfPublisher!=''"
            class="ma-1"
            :ripple="false"
            color="indigo"
            text-color="white"
            label
            x-small
          >
            {{publicationData.nameOfPublisher}}
          </v-chip>
        </div>
      </div>
    </v-list-item-content>
  </div>
</template>


<script>

export default {
  name: 'Publication',
  components: {
  },

  props: {
    publicationData: Object
  },

  data () {
    return {

    }
  },
};
</script>
