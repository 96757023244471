// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import VueGtag from "vue-gtag";

Vue.use(Vuetify)

Vue.use(VueGtag, {
  config: { id: "G-PDNFJ8PYHV" }
});

const opts = {}

export default new Vuetify(opts)
