<template>
  <v-container>
    <h3>Projects</h3>
    <ProjectList :displaySelectedProjects="false"></ProjectList>
  </v-container>
</template>


<script>
import ProjectList from './ProjectList';

export default {
  name: 'Projects',
  components: {
    ProjectList
  },
  methods: {
    login () {
      this.$gtag.event('login', { method: 'Google' })
    }
  },
  data () {
    return {

    }
  },
};
</script>
