<template>
  <v-footer
    bottom
    color="amber lighten-1"
    flat
    padless
    class="justify-center"
  >
    <div style="display: flex; flex-direction: column;">
      <span style="display: flex; justify-content: center;" class="mt-5 mb-5">
        <v-btn
         class="mx-4"
         fab
         dark
         depressed
         small
         color="indigo"
         v-for="link in links"
         :key="link.id"
         :href="link.href"
         target="_blank"
        >
            <v-icon dark>{{link.icon}}</v-icon>
        </v-btn>
      </span>
      <span class="text-center">
        This website was crafted with Vue and seasoned with a dash of love &#9829;.
      </span>
      <span class="mb-5 text-center">
        Copyright &copy; {{new Date().getFullYear()}} Xizi (Lucy) Wang - All Rights Reserved.
      </span>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      links: [
        {id:'0', icon: 'mdi-email', href:'mailto:l84wang@uwaterloo.ca' },
        {id:'1', icon: 'mdi-school', href:'https://scholar.google.ca/citations?hl=en&user=jYcPNvsAAAAJ' },
        {id:'2', icon: 'mdi-github-circle', href:'https://github.com/xiziwang' },
        {id:'3', icon: 'mdi-linkedin', href:'https://www.linkedin.com/in/xizi-lucy-wang' },
      ],
    }
  },
  computed: {
  }
};
</script>
