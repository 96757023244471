<template>
  <v-container v-scroll="handleScroll">
    <About></About>

    <v-divider></v-divider>

    <h3>Selected Publications</h3>
    <PublicationList listType="Selected"/>

    <!--
    <transition name="fade">
      <p v-if="show"> example </p>
    </transition>
    -->

    <v-divider></v-divider>

    <h3>News</h3>
    <News></News>

    <v-divider></v-divider>

    <h3>Selected Projects</h3>
    <ProjectList :displaySelectedProjects="true"></ProjectList>
  </v-container>
</template>


<script>
import About from './About';
import News from './News';
import ProjectList from './ProjectList';
import PublicationList from './PublicationList';

export default {
  name: 'Home',
  components: {
    About,
    News,
    ProjectList,
    PublicationList
  },

  methods: {
    handleScroll: function() {
      if (window.scrollY > 100) {
        this.show = true
      }
    },
    login () {
      this.$gtag.event('login', { method: 'Google' })
    }
  },
  data () {
    return {
      show: false
    }
  },
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
