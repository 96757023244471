<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="6"
        sm="6"
      >
        <v-img :src="profileImgSrc"></v-img>
      </v-col>
      <v-col
        cols="12"
        xl="8"
        lg="8"
        md="6"
        sm="6"
        class="text-center"
      >
        <p>
          <strong>Hi, I'm Xizi (Lucy) Wang!</strong>
        </p>
        <p>
          I am a second-year Ph.D. student at the University of Waterloo, and I am supervised by <a href="https://www.jeffjianzhao.com" target="_blank">Dr. Jian Zhao</a>. I graduated from the University of Michigan - Ann Arbor with a master degree in Information. Before that, I received two bachelor's degrees in Computer Science and Economics from the University of Minnesota - Twin Cities (UMN). My research interests are <b>Human Computer Interaction (HCI)</b>, <b>Computer-Mediated Communication (CMC)</b>, and <b>Computer-Mediated Learning (CML)</b>. Particularly, I am concerned with <b>interaction design</b>, <b>AR/VR</b>, <b>serious game design</b>, <b>wearable technology design</b>, <b>tangible computing</b>, and <b>embodied computing</b>.
        </p>
        <p>
          I love cooking, painting, archery, playing video games, watching animations, playing badminton, ice skating and so on.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'About',
    data () {
      return {
        show: false,
        profileImgSrc: require('./../images/lucy_profile.jpg'),
        text1: ["Front-End Developer", "Web Developer", "Web Designer"],
      }
    }
  }
</script>
