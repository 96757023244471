<template>
  <v-app id="inspire">
    <Navigation/>
      <v-main style="padding-top: 56px">
        <router-view>
          <Home/>
        </router-view>
      </v-main>
    <Footer/>
  </v-app>
</template>


<script>
import Home from './components/Home';
import Navigation from './components/Navigation';
import Footer from './components/Footer';

export default {
  name: 'App',
  components: {
    Home,
    Navigation,
    Footer
  },

  data: () => ({
    //
  }),
};
</script>
