<template>
  <div>
    <v-row dense>
      <template v-for="card in cards">
        <v-col
          v-if="(!displaySelectedProjects | card.selected) & card.shown"
          :key="card.id"
          cols=12
          xl="4"
          lg="4"
          md="6"
          sm="6"
        >
          <v-hover>
            <ProjectCard :cardData="card"/>
          </v-hover>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import ProjectCard from './ProjectCard';

export default {
  name: 'ProjectList',

  props: {
    displaySelectedProjects: Boolean
  },

  components: {
    ProjectCard
  },

  methods: {
  },

  computed: {
  },

  data () {
    return {
      cards: [
        { title: 'Micro-level Gesture Guidance in VR',
          link: "",
          paper: "/papers/chi24-wang.pdf",
          video: "https://youtu.be/FiOlshYAX6U?si=AOcvWY8nR_U9c2ZH",
          github: "",
          detailPage: "/publications",
          tags:['AR/VR', 'Visualization'],
          imgSrc: require('./../images/project_images/card_images/gesture_guidance_card_image.jpg'),
          selected: true,
          subText: 'We explored different types of visual guidance to support fine-grained gesture learning and performance, and found that visual guidance significantly improved users’ gesture performance, reducing time and workload while increasing confidence.',
          shown: true
        },
        { title: 'Cognitive Rehabilitation Training System',
          link: "http://xuzhishi.com/treatment.php",
          paper: "",
          video: "",
          github: "",
          detailPage: "/publications",
          tags:['AR/VR', 'Medication'],
          imgSrc: require('./../images/project_images/card_images/crts_card_image.jpg'),
          selected: true,
          subText: 'Cognitive Rehabilitation Training (CRT) System, consisting of VR-based CRT applications and a web-based management panel, trains and improves the cognitive function of patients with mild cognitive impairment.',
          shown: true
        },
        { title: 'Micro-teaching Classroom',
          link: "",
          paper: "",
          video: "/videos/Micro-teaching_Classroom_Demo.mp4",
          github: "",
          detailPage: "",
          imgSrc: require('./../images/project_images/card_images/microteaching_classroom_card_image.jpg'),
          tags:['AR/VR', 'Education'],
          selected: true,
          subText: 'Micro-teaching Classroom helps pre-service teachers to practice teaching and managing a class in a customized virtual classroom with virtual students. After the training, the system provides evaluations to help the user improve their teaching skills.',
          shown: true
        },
        { title: 'PPVR: Teaching and Learning to Play the Piano in Virtual Reality',
          link: "",
          paper: "/papers/2020_MTOP_Xizi_Lucy_Wang.pdf",
          video: "",
          github: "",
          detailPage: "",
          imgSrc: require('./../images/project_images/card_images/ppvr_card_image.jpg'),
          tags:['AR/VR', 'Education', 'Wearable', 'Collaboration'],
          selected: true,
          subText: 'PPVR is a collaboration system that supports remote piano training in a virtual reality environment with the support of synchronous and asynchronous communication, various viewpoints, and detection of finger pressure.',
          shown: true
        },
        { title: 'weARchemists: An AR Tool For Collaborative Chemistry Learning',
          link: "",
          paper: "",
          video: "/videos/weARchemist_Final_Pitch_2020_Presentation.mp4",
          github: "",
          detailPage: "",
          imgSrc: require('./../images/project_images/card_images/wearchemists_card_image.jpg'),
          tags:['AR/VR', 'Education', 'Collaboration'],
          selected: false,
          subText: 'weARchemists is a mobile application that uses AR technology to support collaborative and remote virtual chemistry experiment conduction.',
          shown: true
        },
        { title: 'Mixed Reality Analytics Toolkit (MRAT)',
          link: "",
          paper: "/papers/3313831.3376330.pdf",
          video: "https://www.youtube.com/watch?v=DG5pqrQPdBc",
          github: "https://github.com/mi2lab/mrat",
          imgSrc: require('./../images/project_images/card_images/mrat_card_image.jpg'),
          detailPage: "",
          tags:['AR/VR', 'Visualization'],
          selected: true,
          subText: 'We demonstrated the MRAT, a toolkit designed to analyze Mixed Reality (MR) experiences, and later evaluated this toolkit by developing an MR application and using this application to conduct a user study.',
          shown: true
        },
        { title: 'Shadoji: View Body Shapes from a Different Angle',
          link: "",
          paper: "/papers/SDC04.pdf",
          video: "/videos/Shadoji_view_body_shape_from_a_different_angle.mp4",
          github: "",
          detailPage: "",
          imgSrc: require('./../images/project_images/card_images/shadoji_card_image.jpg'),
          tags:['Interaction Design'],
          selected: false,
          subText: 'We designed an interactive system named Shadoji to help people who are concerned about their body shape to express their personized beauty standard by creating their unique Shadow Emojis and interacting with other people in a global social community.',
          shown: true
        },
        { title: 'Mediated Social Touch (MST)',
          link: "",
          paper: "/papers/a116-yarosh.pdf",
          video: "",
          github: "",
          detailPage: "",
          imgSrc: require('./../images/project_images/card_images/mst1_card_image.jpg'),
          tags:['Haptics', 'Wearable', 'Collaboration'],
          selected: true,
          subText: 'In the MST project, we analyzed how haptic technologies influence remote relationships and social presence as compared to non-haptic technologies. We demonstrated and evaluated a SqueezeBands system that associates social interactions with haptic actuation over video chatting.',
          shown: true
        },
        { title: 'Self-updating Alcoholics Anonymous (AA) Meeting List',
          link: "",
          paper: "/papers/p83-rubya.pdf",
          video: "",
          github: "",
          detailPage: "",
          imgSrc: require('./../images/project_images/card_images/aa_card_image.jpg'),
          tags:['Information Retrieval', 'Crowdsourcing'],
          selected: false,
          subText: 'We developed and evaluated a semi-automated Human-Aided Information Retrieval (HAIR) approach that first retrieves meeting information from various AA websites and later allows crowd-workers to assist classification.',
          shown: true
        },
        { title: 'Egali Reactive Digital Signage',
          link: "https://kzrl.net/egali-typography-of-radical-support",
          paper: "",
          video: "",
          github: "https://github.com/xiziwang/Egali-Reactive-Digital-Signage",
          detailPage: "",
          imgSrc: require('./../images/project_images/card_images/egali_card_image.jpg'),
          tags:['Interaction Design', 'Accessibility'],
          selected: false,
          subText: 'We developed and evaluated a semi-automated Human-Aided Information Retrieval (HAIR) approach that first retrieves meeting information from various AA websites and later allows crowd-workers to assist classification.',
          shown: true
        },
        { title: 'Moment Capsule',
          link: "https://play.google.com/store/apps/details?id=me.xiziwang.momentcapsule&pli=1",
          paper: "",
          video: "",
          github: "",
          detailPage: "",
          imgSrc: require('./../images/project_images/card_images/moment_capsule_card_image.jpg'),
          tags:['Interaction Design', 'Android Application'],
          selected: false,
          subText: 'Moment Capule allows the user to send letters to future self and receive letter from past self. It is indepedently designed, developed and deployed by myself. It is free and can be downloaded in the Google Play Store.',
          shown: false
        }
      ],
    }
  }
};
</script>
