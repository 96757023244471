<template>
  <v-list>
    <template v-for="p in getList(listType)">
      <v-list-item :key="p.id">
        <Publication :publicationData="p"/>
      </v-list-item>
    </template>
  </v-list>
</template>


<script>
import Publication from './Publication';

export default {
  name: 'PublicationList',
  components: {
    Publication
  },

  props: {
    listType: String
  },

  methods: {
    getList(type) {
      switch(type) {
        case 'Selected':
          return this.selectedList;
        case 'Conference':
          return this.conferenceList;
        case 'Journal':
          return this.journalList;
        case 'Thesis':
          return this.thesisList;
        case 'Competition':
          return this.competitionList;
        default:
          console.log(type);
          return this.selectedList;
      }
    }
  },

  computed: {
    selectedList: {
      get() {
        return this.publications.filter(p => p.selected==true)
      }
    },
    conferenceList: {
      get() {
        return this.publications.filter(p => p.type=='Conference')
      }
    },
    journalList: {
      get() {
        return this.publications.filter(p => p.type=='Journal')
      }
    },
    thesisList: {
      get() {
        return this.publications.filter(p => p.type=='Thesis')
      }
    },
    competitionList: {
      get() {
        return this.publications.filter(p => p.type=='Student Design Competition')
      }
    },
  },

  data () {
    return {
      publications: [
        {
          type: "Conference",
          nameOfPublisher: "CHI'24",
          year: 2024,
          content: "<b>Xizi Wang</b>, Ben Lafreniere, and Jian Zhao. 2024. Exploring Visualizations for Precisely Guiding Bare Hand Gestures in Virtual Reality. In Proceedings of the 2024 CHI Conference on Human Factors in Computing Systems (CHI'24). DOI: <a href='https://doi.org/10.1145/3613904.3642935' target='_blank'> https://doi.org/10.1145/3613904.3642935 </a>",
          paper: "/papers/chi24-wang.pdf",
          video: "https://youtu.be/FiOlshYAX6U?si=AOcvWY8nR_U9c2ZH",
          link: "",
          github: "",
          imgSrc: require('./../images/project_images/card_images/gesture_guidance_card_image.jpg'),
          selected: true,
          comingSoon: false
        },
        {
          type: "Journal",
          nameOfPublisher: "IJHCS",
          year: 2022,
          content: "Svetlana Yarosh, <b>Xizi Wang</b>, and Yuan Yao. 2022. Perceptions of Mediated Social Touch: Experimental Investigation of the Role of Haptic Actuation, Relationship, and Emotional Salience. Int. J. Hum.-Comput. Stud. 159, C (Mar 2022). DOI: <a href='https://doi.org/10.1016/j.ijhcs.2021.102757' target='_blank'> https://doi.org/10.1016/j.ijhcs.2021.102757 </a>",
          paper: "/papers/1-s2.0-S1071581921001750-main.pdf",
          video: "",
          link: "",
          github: "",
          imgSrc: require('./../images/project_images/card_images/mst2_card_image.jpg'),
          selected: true,
          comingSoon: false
        },
        {
          type: "Thesis",
          nameOfPublisher: "",
          year: 2020,
          content: "<b>Xizi (Lucy) Wang</b>. 2020. PPVR: Teaching and Learning to Play the Piano in Virtual Reality.",
          paper: "/papers/2020_MTOP_Xizi_Lucy_Wang.pdf",
          video: "",
          link: "",
          github: "",
          imgSrc: require('./../images/project_images/card_images/ppvr_card_image.jpg'),
          selected: false,
          comingSoon: false
        },
        {
          type: "Conference",
          nameOfPublisher: "CHI'20",
          year: 2020,
          content: "Michael Nebeling, Maximilian Speicher, <b>Xizi Wang</b>, Shwetha Rajaram, Brian D. Hall, Zijian Xie, Alexander R. E. Raistrick, Michelle Aebersold, Edward G. Happ, Jiayin Wang, Yanan Sun, Lotus Zhang, Leah E. Ramsier, and Rhea Kulkarni. 2020. MRAT: The Mixed Reality Analytics Toolkit. In Proceedings of the 2020 CHI Conference on Human Factors in Computing Systems (CHI '20). Association for Computing Machinery, New York, NY, USA, 1–12. DOI:<a href='https://doi.org/10.1145/3313831.3376330' target='_blank'>https://doi.org/10.1145/3313831.3376330</a>",
          paper: "/papers/3313831.3376330.pdf",
          video: "https://www.youtube.com/watch?v=DG5pqrQPdBc",
          link: "",
          github: "https://github.com/mi2lab/mrat",
          imgSrc: require('./../images/project_images/card_images/mrat_card_image.jpg'),
          isBestPaper: true,
          selected: true,
          comingSoon: false
        },
        {
          type: "Student Design Competition",
          nameOfPublisher: "CHI EA'19",
          year: 2019,
          content: "Xuan Jin<sup>*</sup>, Peipei Nie<sup>*</sup>, <b>Xizi Wang</b><sup>*</sup>, Zhuo Wang<sup>*</sup>, and Chunying Zhang<sup>*</sup>. 2019. Shadoji: View Body Shapes from a Different Angle. In Extended Abstracts of the 2019 CHI Conference on Human Factors in Computing Systems (CHI EA '19). ACM, New York, NY, USA, Paper SDC04, 6 pages. DOI: <a href='https://doi.org/10.1145/3290607.3309686' target='_blank'> https://doi.org/10.1145/3290607.3309686</a>",
          paper: "/papers/SDC04.pdf",
          video: "/videos/Shadoji_view_body_shape_from_a_different_angle.mp4",
          link: "",
          github: "",
          imgSrc: require('./../images/project_images/card_images/shadoji_card_image.jpg'),
          selected: false,
          comingSoon: false
        },
        {
          type: "Conference",
          nameOfPublisher: "CHIIR'19",
          year: 2019,
          content: "Sabirat Rubya, <b>Xizi Wang</b>, and Svetlana Yarosh. 2019. HAIR: Towards Developing a Global Self-Updating Peer Support Group Meeting List Using Human-Aided Information Retrieval. In Proceedings of the 2019 Conference on Human Information Interaction and Retrieval (CHIIR '19). ACM, New York, NY, USA, 83-92. DOI: <a href='https://doi.org/10.1145/3295750.3298933' target='_blank'>https://doi.org/10.1145/3295750.3298933</a>",
          paper: "/papers/p83-rubya.pdf",
          video: "",
          link: "",
          github: "",
          imgSrc: require('./../images/project_images/card_images/aa_card_image.jpg'),
          selected: false,
          comingSoon: false
        },
        {
          type: "Conference",
          nameOfPublisher: "CSCW'17",
          year: 2017,
          content: "Svetlana Yarosh, Kenya Mejia, Baris Unver, <b>Xizi Wang</b>, Yuan Yao, Akin Campbell, and Brad Holschuh. 2017. SqueezeBands: Mediated Social Touch Using Shape Memory Alloy Actuation. Proc. ACM Hum.-Comput. Interact. 1, CSCW, Article 116 (December 2017), 18 pages. DOI: <a href='https://doi.org/10.1145/3134751' target='_blank'>https://doi.org/10.1145/3134751</a>",
          paper: "/papers/a116-yarosh.pdf",
          video: "",
          link: "",
          github: "",
          imgSrc: require('./../images/project_images/card_images/mst1_card_image.jpg'),
          selected: true,
          comingSoon: false
        }
      ]
    }
  },
};
</script>
